<template>
    <div class="student-wrapper">
        <div class="header">
            <div class="header-time" v-if="comType === 1">
                <span>{{txtTitle}}</span><time-count-down :left-time="leftTime" @change="updateTime"></time-count-down>
            </div>
            <div class="header-right">
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userIcon ? userIcon : require('../../assets/images/user_img.png')" alt=""/>
                    </div>
                    <div class="user-name">{{username}}</div>
                </div>
                <div class="exit-btn" @click="studentLogout">
                    <i class="iconfont">&#xe698;</i>
                    <span>退出</span>
                </div>
            </div>
        </div>
        <el-scrollbar class="container" :native="false">
            <template>
                <div class="multiple-content">
                    <div class="dialog-box-shadow" v-if="isStart"></div>
                    <div class="competition-title" v-if="comType === 1">
                        <div class="competition-txt">
                            <h4>{{name}}</h4>
                            <p>{{introduce}}</p>
                        </div>
                    </div>
                    <template>
                        <div class="multiple-box box-two">
                            <router-link v-if="includeOperation" :to="{path: '/student/competition/index'}"
                                         class="mul-item competition-item">
                                <div class="mul-title">{{comType === 1?'实操题竞赛':'实操题训练'}}</div>
                                <div class="img-box">
                                    <img :src="require('../../assets/images/studentIndex/competition_back.png')" alt="">
                                </div>
                            </router-link>
                            <router-link v-if="isTheory" :to="{path: comType===1?'/student/theorycompetition?id=' + comId :'/student/train/index'}"
                                         class="mul-item competition-item">
                                <div class="mul-title">{{comType === 1?'理论题竞赛':'理论题训练'}}</div>
                                <div class="img-box">
                                    <img :src="require('../../assets/images/studentIndex/train_back.png')" alt="">
                                </div>
                            </router-link>
                        </div>
                    </template>
                </div>
            </template>
        </el-scrollbar>
        <el-dialog title="提示" :visible.sync="endDialog" width="550px" custom-class="end-dialog" @close="studentLogout">
            <div class="end-competition">
                <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                <div class="text">{{comType === 1?'竞赛已结束~':'训练已结束~'}}</div>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" class="btn-red" @click="studentLogout">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import TimeCountDown from "@/components/plugs/timeCountDown";
    export default {
        components: {TimeCountDown},
        data() {
            return {
                username: '',
                userIcon: '',
                //比赛介绍
                introduce: '',
                //比赛名称
                name: '',
                //比赛时间
                beginTime: '',
                endTime: '',
                isStart: true,
                isType: 1,
                //模块列表
                tmlList: [],
                // 比赛结束弹窗
                endDialog: false,
                trainInfo: {},
                comType: 1,//竞赛：1；训练：2
                comId: null,
                leftTime: 10000,
                txtTitle: '',
                isTheory: false,
                includeOperation: true,
            }
        },
        mounted() {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            if (this.username === null) {
                this.$message({
                    type: 'warning',
                    message: '请先登录',
                    onClose: () => {
                        this.studentLogout();
                    }
                });
            }
            this.getStudentComeInfo();
        },
        methods: {
            updateTime(val) {
                this.leftTime = Number(val);
                if (this.isStart && this.leftTime <= 0) {
                    this.isStart = false;
                    this.leftTime = this.endTime - this.beginTime;
                    this.txtTitle = "距离比赛结束：";
                }
                if (this.leftTime <= 0) {
                    this.$message({
                      type: 'success',
                      message: '竞赛结束',
                      duration: 2000,
                      onClose: () => {
                        localStorage.clear();
                        this.$router.push('/');
                      }
                    });
                }
            },
            changeStatusCompetition(val) {
                this.isStart = val;
            },
            //获取比赛信息
            getStudentComeInfo() {
                let now_time = Date.parse(new Date()) / 1000;
                this.$http.axiosGet(this.$api.studentComInfo, (res) => {
                    if (res.data.competition && res.data.competition.modules) {
                        let modules = res.data.competition.modules.map((item) => {
                            return item.tmpl_module_type
                        })
                        if (Number(res.data.competition.type) === 1 && modules.indexOf(5) !== -1) {
                            this.isTheory = true
                        }
                        if (modules.length === 1 && modules[0] === 5) {
                            this.includeOperation = false
                        }
                    } else {
                        this.includeOperation = false
                    }

                    if (res.code === 200) {
                        //有train字段的是由训练管理员创建的
                        if (res.data.train) {
                            this.trainInfo = res.data.train
                            this.comType = 2
                            this.isStart = false;
                            this.isTheory = true
                        }
                        let response_time = Date.parse(new Date()) / 1000;
                        let process_time = response_time - now_time;
                        if (res.data.competition) {
                            this.comId = res.data.competition.id;
                            this.comType = res.data.competition.type
                            this.introduce = res.data.competition.introduce;
                            this.name = res.data.competition.name;
                            //获取开始时间
                            this.beginTime = res.data.competition.begin_time;
                            this.endTime = res.data.competition.end_time;
                            // 获取当前时间
                            let newTime = Number(res.data.competition.time) - process_time;
                            this.$lockr.set('current_time', newTime);
                            this.$lockr.set('begin_time', res.data.competition.begin_time);
                            this.$lockr.set('end_time', res.data.competition.end_time);
                            this.$lockr.set('competition_type', res.data.competition.type);
                            this.isType = Number(res.data.competition.type);
                            //开始比赛
                            if ((newTime - this.beginTime) > 0) {
                                this.isStart = false;
                                this.txtTitle = "距离比赛结束：";
                                this.leftTime = Number(this.endTime) - newTime;
                            } else {
                                this.isStart = true;
                                this.txtTitle = "距离比赛开始：";
                                this.leftTime = Number(this.beginTime) - newTime;
                            }
                            // 结束比赛的弹窗
                            if (newTime - this.endTime > 0) {
                                this.endDialog = true
                            }
                        }
                    } else {
                        // if(res.status == 204) {
                        //     this.$message.warning(res.msg)
                        //     setTimeout(() => {
                        //         this.studentLogout();
                        //     }, 1500)
                        // } else {
                            this.$message.warning(res.msg)
                            // this.endDialog = true
                        // }

                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //退出
            studentLogout() {
                this.$http.axiosGet(this.$api.out, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: res.msg,
                            onClose: () => {
                                this.$lockr.flush();
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/') {
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            duration: 1000,
                            message: res.msg,
                            onClose: () => {
                                this.$lockr.flush();
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/') {
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-wrapper {
        .header {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            background: #fff;
            position: relative;
            .header-time {
                display: flex;
                flex: 1;
                height: 100%;
                justify-content: center;
                align-items: center;
                &::v-deep span {
                    font-size: 18px;
                    color: #E21D1A;
                }
            }

            .train-manage {
                border: 1px solid #FD4446;
                padding: 10px 11px;
                position: absolute;
                left: 60px;
                top: 22px;
                color: #FD4446;

                &:hover {
                    color: #fd4446d9;
                    background-color: #fff;
                    border: 1px solid #fd4446d9;
                }
            }

            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute;
                right: 30px;
                line-height: 80px;

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;

                    .iconfont {
                        font-size: 20px;
                    }

                    span {
                        margin-left: 6px;
                    }

                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }

        .container {
            width: 100%;
            height: calc(100vh - 60px);
            background: url("../../assets/images/studentIndex/index-bg.png") no-repeat bottom center, linear-gradient(#04BEFE, #4482EB);
            background-size: cover;
            position: relative;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
            }

            .multiple-content {
                .dialog-box-shadow {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 999;
                    cursor: not-allowed;
                }
                .competition-title {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    .competition-txt {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    &::v-deep {
                        h4 {
                            font-size: 24px;
                            color: #FFF;
                            margin: 20px 0px;
                        }
                        p {
                            color: #FFF;
                            margin: 0;
                            padding: 0;
                            width: 500px;
                            text-align: left;
                            font-size: 18px;
                        }

                    }
                }
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .multiple-box {
                    height: 70%;
                    display: flex;
                    /*max-height: 450px;*/
                    .mul-item {
                        border-radius: 20px;
                        background-color: #EDFDFF;
                        background-repeat: no-repeat;
                        background-position: bottom center;
                        overflow: hidden;

                        .mul-title {
                            color: #333;
                            font-size: 30px;
                            text-align: center;
                            margin: 70px 0;
                            font-weight: 500;
                        }

                        .img-box {
                            margin: 0 30px 30px;
                        }
                    }

                    &.box-two {
                        .mul-item {
                            /*width: 34%;*/
                            /*margin: 0 8%;*/
                            /*background-size: auto 63%;*/
                            /*width: calc(50% - 60px);*/
                            //width: 50%;
                            margin-right: 45px;
                            background-size: auto 60%;

                            &:last-child {
                                margin-right: 0;
                                margin-left: 45px;
                            }
                        }
                    }
                }
            }
        }
    }

    ::v-deep .end-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #eee;
        }

        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
            color: #FD4446;
        }

        .end-competition {
            text-align: center;

            img {
                height: 170px;
            }

            .text {
                margin-top: 28px;
                color: #666;
                font-size: 18px;
            }
        }
    }
</style>